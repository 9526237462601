<template>
  <div class="item condition_item">
    <div v-if="icon" class="logo_link">
      <i :class="icon"/>

      <div v-if="faza !== null" :class="'faza faza_' + faza">
        {{ $constants.typeFazaShort[faza] ? $t($constants.typeFazaShort[faza]) : '' }}
      </div>
      <div v-else-if="percent" :class="'faza faza_gray'">
        {{ percent }}%
      </div>
    </div>

    <template v-else-if="logo && logo_link">
      <NuxtLink class="logo_link" :to="logo_link">
        <img :src="logo" class="logo" data-ratio="40-40" :title="hint" alt="">
        <div v-if="faza !== null" :class="'faza faza_' + faza">
          {{ $constants.typeFazaShort[faza] ? $t($constants.typeFazaShort[faza]) : '' }}
        </div>
        <div v-else-if="percent" :class="'faza faza_gray'">
          {{ percent }}%
        </div>
      </NuxtLink>
    </template>

    <template v-else-if="logo">
      <div class="logo_link">
        <img :src="logo" class="logo" data-ratio="40-40" :title="hint" alt="">
        <div v-if="faza !== null" :class="'faza faza_' + faza">
          {{ $constants.typeFazaShort[faza] ? $t($constants.typeFazaShort[faza]) : '' }}
        </div>
        <div v-else-if="percent" :class="'faza faza_gray'">
          {{ percent }}%
        </div>
      </div>
    </template>

    <div class="info">
      <NuxtLink v-if="name_link" class="name" :to="name_link">{{ name }}</NuxtLink>
      <div v-else class="name">{{ name }}</div>

      <div v-if="hint_link" class="ttl">
        <template v-if="props.count > 1">
          <span class="cnt">x{{ props.count }}</span>
        </template>
        <NuxtLink class="lnk" :to="hint_link">{{ hint }}</NuxtLink>
      </div>
      <div v-else class="ttl">{{ hint ?? 'Custom' }}</div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  icon: {
    type: String,
    default: null
  },
  logo: {
    type: String,
    default: null
  },
  logo_webp: {
    type: String,
    default: null
  },
  logo_link: {
    type: String,
    default: null
  },
  name: {
    type: String,
    default: null
  },
  name_link: {
    type: String,
    default: null
  },
  hint: {
    type: String,
    default: null
  },
  faza: {
    type: Number,
    default: null
  },
  percent: {
    type: Number,
    default: null
  },
  count: {
    type: Number,
    default: null
  },
  hint_link: {
    type: String,
    default: null
  },
  dataProps: {
    type: Object,
    default: () => {
      return {}
    }
  }
})
</script>

<style scoped>


.item.condition_item{
  cursor: help;
}
.item{
  display: flex;
  position: relative;
  align-items: center;
  height: 45px;
}
.item .logo{
  max-height: 40px;
  max-width: 40px;
  margin-right: 10px;
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: contain;
}
.item .logo_link{
  min-width: 40px;
}
.item .faza{
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: white;
  font-size: 0.5rem;
  font-weight: bold;
  padding: 0px 3px;
  border-radius: 3px;
  border: 1px gray solid;
  line-height: 0.56rem;
  left: 7px;
}
.item .faza.faza_0{

  border: 1px #a2da7a solid;
  color: #a2da7a;
}
.item .faza.faza_gray{

  border: 1px #000000 solid;
  color: #000000;
}
.item .faza.faza_1{
  border: 1px #ff8853 solid;
  color: #ff8853;
}
.item .info{
  display: flex;
  flex-direction: column;
  /*width: calc(100% - 46px);*/
}
.item .info .name{
  text-transform: capitalize;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
}
.item .info .ttl{
  /* overflow: hidden; */
  text-overflow: ellipsis;
  text-transform: capitalize;
  color: var(--gd-text-gray-color);
  white-space: nowrap;
}
.item .info .ttl .lnk{
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  color: var(--gd-text-gray-color);
  white-space: nowrap;
}

.item .info .ttl .bn{
  background-color: orange;
  align-items: center;
  justify-content: center;
  padding: 3px;
  display: inline-block;
  border-radius: 3px;
  color: black;
  font-size: 0.9rem;
  margin-left: 4px;
  cursor: pointer;
  margin-top: -2px;
}
.item .info .ttl .bn{
  background-color: #ffaf1d;
  color: black;
}
.item .info .ttl .cnt{
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 2px;
  padding: 0px 3px;
  text-transform: none;
  font-weight: bold;

}
.item.partner{
  background-color: #fff8d5;
  padding: 5px;
  border-radius: 3px;
}
.item .action{
  margin-left: 10px;
}
.item .action a{
  color: var(--un-text-color);
}

.ic{
  display: inline-block;
  width: 40px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 10px;
}

.ic-room-1{ background-image: url(/images/setup_diary/setup_diary_icons-01.svg); }
.ic-room-2{ background-image: url(/images/setup_diary/setup_diary_icons-02.svg); }

.ic-soil-1{ background-image: url(/images/setup_diary/setup_diary_icons-03.svg); }
.ic-soil-2{ background-image: url(/images/setup_diary/setup_diary_icons-04.svg); }
.ic-soil-3{ background-image: url(/images/setup_diary/setup_diary_icons-05.svg); }

/* .ic-method-1{ background-image: url(/images/setup_diary/setup_diary_icons-0.svg); } */
.ic-method-2{ background-image: url(/images/setup_diary/setup_diary_icons-09.svg); }
.ic-method-3{ background-image: url(/images/setup_diary/setup_diary_icons-16.svg); }
.ic-method-4{ background-image: url(/images/setup_diary/setup_diary_icons-10.svg); }
.ic-method-5{ background-image: url(/images/setup_diary/setup_diary_icons-17.svg); }
.ic-method-6{ background-image: url(/images/setup_diary/setup_diary_icons-13.svg); }
.ic-method-7{ background-image: url(/images/setup_diary/setup_diary_icons-14.svg); }
.ic-method-8{ background-image: url(/images/setup_diary/setup_diary_icons-11.svg); }
.ic-method-9{ background-image: url(/images/setup_diary/setup_diary_icons-12.svg); }
/* .ic-method-10{ background-image: url(/images/setup_diary/setup_diary_icons-18.svg); } */
.ic-method-11{ background-image: url(/images/setup_diary/setup_diary_icons-15.svg); }

.ic-light-1{ background-image: url(/images/setup_diary/setup_diary_icons-07.svg); }
.ic-light-2{ background-image: url(/images/setup_diary/setup_diary_icons-08.svg); }
.ic-light-3{ background-image: url(/images/setup_diary/setup_diary_icons-06.svg); }


.ic-medium-1{ background-image: url(/images/medium/1.svg); }
.ic-medium-2{ background-image: url(/images/medium/2.svg); }
.ic-medium-3{ background-image: url(/images/medium/3.svg); }
.ic-medium-4{ background-image: url(/images/medium/4.svg); }
.ic-medium-5{ background-image: url(/images/medium/5.svg); }
.ic-medium-6{ background-image: url(/images/medium/6.svg); }
.ic-medium-7{ background-image: url(/images/medium/7.svg); }





</style>